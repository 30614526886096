import React, { type MouseEvent, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { format } from 'date-fns'
import styled, { css } from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import { GOOGLE_API_KEY, ORDER_TYPES } from 'utils/constants'
import { formatPrice, codeToLocale, getTranslatedTextByKey, getLocaleStr } from 'utils/utils'
import { limitTextNumberOfLines, breakpoints, respondAbove, respondTo } from 'styles/mixins'
import TypographyPro from 'themes/TypographyPro'
import ButtonBase from 'components/common/ButtonBase'
import type { OrderEntity } from 'types/OrderEntity'
import { getDateFormatByLocale } from 'utils/timeUtils'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type _Application from 'mobx/Application'
import type _MobileApplication from 'mobx/MobileApplication'
import type _ItemAdditions from 'mobx/ItemAdditions'
import { useStores } from 'hooks/useStores'

import { useReorder } from 'hooks/useReorder'
import type { ButtonBaseProps } from 'components/common/ButtonBase'

const OrderFirstItemName = styled(TypographyPro)`
	text-transform: uppercase;
	color: var(--strokeMenuTitle);
	${limitTextNumberOfLines(1)}
`

const MoreItems = styled(TypographyPro)`
	color: var(--fonts);
`

const PriceText = styled(TypographyPro)`
	color: var(--clear);
`

const TimeText = styled(TypographyPro)`
	text-transform: capitalize;
	color: var(--inactive);
	text-align: end;
`

const DeliveryMethodText = styled(TimeText)`
	text-align: start;
	color: var(--primary);
	text-transform: uppercase;
`

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
	width: 100%;
	overflow: hidden;
	margin-bottom: ${(props: { height?: string; $marginBottom?: string }) => (props.$marginBottom ? props.$marginBottom : '')};
	height: ${(props: { height?: string; $marginBottom?: string }) => (props.height ? props.height : 'auto')};
`

const Container = styled.div<{ $isHomePage?: boolean; $isMobileView?: boolean }>`
	height: ${(props: { $isMobileView?: boolean; $isHomePage?: boolean }) => (props.$isMobileView ? '179px' : '192px')};

	${({ $isHomePage }) =>
		$isHomePage
			? css`
					min-width: 400px;
					${respondTo.sm`
					min-width: 320px;
					child
				`}
			  `
			: css`
					width: 90%;
					${respondAbove.xl`
						min-width: 398px;
					`}
			  `}

	display: flex;
	flex-direction: column;
	background: #ffffff;
	justify-content: space-between;
	padding: 20px;
	border-radius: var(--borderRadiusFrame);
	box-shadow: var(--defaultShadow1);

	${respondAbove.xl`
		width: 393px;
	`}
`

const ReorderButton = styled<React.FC<ButtonBaseProps>>(ButtonBase)`
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	width: 100%;
`

const getOrderType = (orderType: string) => {
	// TODO: Should use type here !
	if (!orderType) {
		return '---'
	}
	switch (orderType.toLowerCase()) {
		case ORDER_TYPES.PEAKUP:
			return getTranslatedTextByKey(ORDER_TYPES.PEAKUP, 'Pickup')
		case ORDER_TYPES.DELIVERY:
			return getTranslatedTextByKey(ORDER_TYPES.DELIVERY, 'Delivery')
		default:
			return orderType
	}
}

interface Store {
	Infra?: typeof _Infra
	User?: typeof _User
	Application?: typeof _Application
	MobileApplication?: typeof _MobileApplication
	ItemAdditions?: typeof _ItemAdditions
}

type OrderProps = {
	orderData: OrderEntity
	width?: string
	isHomePage?: boolean
} & Store

const Order = inject(
	'Infra',
	'User',
	'Application'
)(
	observer(({ orderData, isHomePage, Infra, User, Application }: OrderProps) => {
		const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` })
		const _currency = Infra?.appParams?.currency || 'EUR'
		const preferredLanguage = User?.preferredLanguage || 'en'
		const langFromInfra = Infra?.appParams?.l || 'en'
		const locale = User?.preferredLanguage ? codeToLocale[preferredLanguage] : codeToLocale[langFromInfra]
		const _key = Infra?.locale?.key?.trim() || GOOGLE_API_KEY
		const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
			apiKey: _key,
		})
		const { addressManagerV2Store } = useStores()

		const { reorder } = useReorder(Infra)

		useEffect(() => {
			// fetch place details for the first element in placePredictions array
			if (placePredictions.length) {
				placesService?.getDetails(
					{
						placeId: placePredictions[0].place_id,
					},
					async (placeDetails: any) => {
						// attempt the re-order
						const newMenuPath = await reorder(orderData)
						if (newMenuPath) {
							addressManagerV2Store?.setFullAddress(placeDetails, orderData.orderType)
						}
					}
				)
			}
			Infra?.setLoading(false)
		}, [placePredictions])

		const openPastOrderSummary = () => {
			const DAY_IN_MS = 24 * 60 * 60 * 1000
			const isOrderRecent = orderData.lastUsed && new Date(orderData.lastUsed).getTime() > new Date().getTime() - DAY_IN_MS
			const isLinkDefined = !!orderData?.props?.orderConfirmationURL
			if (isOrderRecent && isLinkDefined) {
				if (Application?.isMobileApp) {
					window.location.href = orderData?.props?.orderConfirmationURL
				} else {
					// @ts-ignore
					window?.open(orderData?.props?.orderConfirmationURL, '_blank').focus()
				}
			}
		}

		const handleReorderClick = (e: MouseEvent) => {
			// prevent opening this order's summary page in a new tab
			e.stopPropagation()
			Infra?.setLoading(true)
			getPlacePredictions({ input: orderData.address.formatted })
		}

		const moreItemsText = (order: any) => {
			const totalOrderItems = order?.courseList?.length
			const moreItemsText = `+${totalOrderItems - 1} ${getTranslatedTextByKey('eCommerce.accountSettings.moreItemsInTheOrder', 'more item(s)')}`
			return totalOrderItems > 1 ? <MoreItems variant="Subhead">{moreItemsText}</MoreItems> : ''
		}

		const orderFirstItemName = orderData?.courseList?.length && getLocaleStr(orderData?.courseList?.[0].title, locale)

		return (
			<Container
				data-testid="past-order-container"
				className="pastOrderContainer"
				$isHomePage={isHomePage}
				$isMobileView={isMobileView}
				onClick={() => openPastOrderSummary()}
			>
				<Row>
					<OrderFirstItemName data-testid="past-order-item-name" variant="h4">
						{orderFirstItemName}
					</OrderFirstItemName>
				</Row>

				<Row $marginBottom="10px" height="24px">
					{moreItemsText(orderData)}
				</Row>

				<Row $marginBottom="6px">
					<TimeText variant="BodySmall">
						{orderData?.lastUsed ? getDateFormatByLocale(new Date(orderData?.lastUsed), Infra?.locale?.msg) : ''}
					</TimeText>
					<TimeText variant="BodySmall">{orderData?.lastUsed ? format(new Date(orderData?.lastUsed), 'HH:mm a') : ''}</TimeText>
				</Row>

				<Row $marginBottom="10px">
					<DeliveryMethodText variant="h5">
						{orderData?.orderType?.toLowerCase() === ORDER_TYPES.PEAKUP
							? getTranslatedTextByKey('eCommerce.orderType.pickup', 'Pickup')
							: getOrderType(orderData?.orderType)}
					</DeliveryMethodText>
				</Row>

				<Row>
					<ReorderButton data-testid="view-order-button" className="pastOrderButton" onClick={handleReorderClick}>
						{getTranslatedTextByKey('eCommerce.accountSettings.viewOrderButton', 'View Order')}
						<PriceText variant="CTASmall">{formatPrice(orderData?.grandTotalIncludingAll, _currency, locale)}</PriceText>
					</ReorderButton>
				</Row>
			</Container>
		)
	})
)

export default Order
