import { initSessionFromReOrder, isReorderable } from 'utils/cartUtils/cartUtils'
import { useStores } from './useStores'
import type { OrderEntity } from 'types/OrderEntity'
import { useRouter } from 'next/router'
import type _Infra from 'mobx/Infra'
import { getTranslatedTextByKey } from 'utils/language'

export const useReorder = (Infra: typeof _Infra | undefined) => {
	const { cartStore } = useStores()
	const router = useRouter()

	const reorder = async (order: OrderEntity): Promise<string | undefined> => {
		const canReOrder = await isReorderable(order)
		if (!canReOrder) {
			return
		}
		const newMenuPath = await initSessionFromReOrder(order)
		if (!newMenuPath) {
			return
		}
		await cartStore.reOrder(order)
		const expectedNumberOfItems = order.courseList.length
		const actualNumberOfItems = cartStore.numberOfItems

		let redirectURL = newMenuPath.replace('/menu?', '/checkout?')
		let message = ''

		if (!actualNumberOfItems) {
			redirectURL = newMenuPath
			message = getTranslatedTextByKey('webviewFlow.reorder.allItemsUnavailable', 'All items in this order are currently unavailable')
		} else if (expectedNumberOfItems !== actualNumberOfItems) {
			redirectURL = newMenuPath.replace('/menu?', '/checkout?')
			message = getTranslatedTextByKey('webviewFlow.reorder.someItemsUnavailable', 'Some items in this order are currently unavailable')
		}

		await router.push(redirectURL)

		if (message) {
			Infra?.setNotification?.({
				open: true,
				title: message,
				message: '',
				onClose: () => {
					Infra?.closeNotification()
				},
				okAction: () => {
					Infra?.closeNotification()
				},
			})
		}

		return newMenuPath
	}

	return {
		reorder,
	}
}
